<template>
  <div class="details-vue">
    <Header class="responsive-bar">
      <template v-slot:title>
        {{ $t("personal-information-2433db5a329ea473271a3cd8c013362e") }}
      </template>
    </Header>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <div
          v-if="get_profile.address"
          class="bg-white shadow rounded-md max-w-5xl m-auto mt-5"
        >
          <div class="border-b border-gray-200">
            <div class="flex justify-between">
              <h3 class="text-lg leading-6 font-normal text-gray-900">
                {{ $t("my-profile") }}
              </h3>
              <div></div>
            </div>
            <p class="mt-1 pb-4 text-sm leading-5 font-light text-gray-500">
              {{ $t("manage-how-information-is-displayed-on-your-account") }}
            </p>
          </div>
          <div>
            <dl>
              <div
                v-if="
                  whitelabel === 'ICMCapital' &&
                  get_user_data.extra.client_type === 'company'
                "
                class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="my-auto text-sm leading-5 font-normal">
                  {{ $t("company-name") }}
                </dt>
                <!-- <dd class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1" v-if="showUpdateCompanyName">
                  <ValidationProvider :rules="{required: true, alpha_spaces: true}" name="name" v-slot="{ errors }">
                    <div class="rounded-md shadow-sm">
                      <input id="company_name" v-model="updatedUser.company_name" class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                    </div>
                    <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd> -->
                <dd
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_profile.company_name }}
                </dd>
                <!-- <dd class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1" v-if="get_profile.state === 'Demo' || userHasPermission">
                  <button type="reset"
                          v-if="showUpdateCompanyName"
                          @click="showUpdateCompanyName = false, cancel()"
                          class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
                    {{ $t('Cancel') }}
                  </button>
                  <button v-if="showUpdateCompanyName"
                          type="submit"
                          class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
                    {{ $t('save-changes') }}
                  </button>
                  <div v-else
                       @click="showUpdateCompanyName = true"
                       class="sm:float-right float-none  relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
                    {{ $t('update') }}
                  </div>
                </dd> -->
              </div>

              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="my-auto text-sm leading-5 font-normal my-auto">
                  {{ $t("first-name-20db0bfeecd8fe60533206a2b5e9891a") }}
                </dt>
                <dd
                  v-if="showUpdateFirstName"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, alpha_spaces: true }"
                    name="name"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="first_name"
                        v-model="updatedUser.first_name"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_profile.first_name }}
                </dd>
                <dd
                  v-if="
                    (get_profile.state === 'Demo' || userHasPermission) &&
                    whitelabel !== 'ICMMENA' &&
                    whitelabel !== 'ICMCapital'
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateFirstName"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateFirstName = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateFirstName"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateFirstName = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="my-auto text-sm leading-5 font-normal my-auto">
                  {{ $t("last-name-8d3f5eff9c40ee315d452392bed5309b") }}
                </dt>
                <dd
                  v-if="showUpdateLastName"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, alpha_spaces: true }"
                    name="last_name"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="last_name"
                        v-model="updatedUser.last_name"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_profile.last_name }}
                </dd>
                <dd
                  v-if="
                    (get_profile.state === 'Demo' || userHasPermission) &&
                    whitelabel !== 'ICMMENA' &&
                    whitelabel !== 'ICMCapital'
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateLastName"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateLastName = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateLastName"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateLastName = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div
                v-if="whitelabel === 'OrbitInvest'"
                class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="my-auto text-sm leading-5 font-normal my-auto">
                  {{ $t("company-name") }}
                </dt>
                <dd
                  v-if="showUpdateCompanyName"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, alpha_spaces: true }"
                    name="name"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="company_name"
                        v-model="updatedUser.company_name"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_profile.company_name }}
                </dd>
                <dd
                  v-if="get_profile.state === 'Demo' || userHasPermission"
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateCompanyName"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateCompanyName = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateCompanyName"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateCompanyName = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm my-auto leading-5 font-normal sm:col-span-1">
                  {{ $t("Email") }}
                </dt>
                <dd
                  v-if="showUpdateEmail"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="email"
                        v-model="updatedUser.email"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        type="email"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1 break-words"
                >
                  {{ get_profile.email }}
                </dd>
                <dd
                  v-if="
                    whitelabel !== 'TD365' &&
                    whitelabel !== 'TDSouthAfrica' &&
                    whitelabel !== 'Blackstone' &&
                    whitelabel !== 'EagleGlobalMarkets' &&
                    whitelabel !== 'OneTradingMarkets' &&
                    whitelabel !== 'TradeCoreUK' &&
                    whitelabel !== 'ICMMENA' &&
                    whitelabel !== 'ICMCapital' &&
                    (get_profile.state === 'Demo' || userHasPermission)
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateEmail"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateEmail = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateEmail"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateEmail = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm my-auto leading-5 font-normal">
                  {{ $t("Phone") }}
                </dt>
                <dd
                  v-if="showUpdatePhone"
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true, validatePhone: !phoneValid }"
                    name="telephone"
                  >
                    <div class="rounded-md shadow-sm">
                      <VuePhoneNumberInput
                        v-model="updatedUser.address.telephone"
                        :border-radius="6"
                        :disabled-fetching-country="true"
                        :no-use-browser-locale="true"
                        color="#a4cafe"
                        error-color="#E53E3E"
                        valid-color="#d2d6dc"
                        @update="phoneValidateCheck($event), (phone = $event)"
                      />
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-if="!showUpdatePhone"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{ get_profile.address.telephone }}
                </dd>
                <dd
                  v-if="
                    ((get_profile.state === 'Demo' || userHasPermission) &&
                      whitelabel !== 'ICMMENA') ||
                    whitelabel === 'TD365' ||
                    whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'Blackstone' ||
                    whitelabel === 'OneTradingMarkets' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK'
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdatePhone"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdatePhone = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdatePhone"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdatePhone = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                <dt class="text-sm leading-5 font-normal my-auto">
                  {{ $t("Country") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {{ get_profile.address.addr_country }}
                </dd>
              </div>

              <!-- Nationality -->
              <div
                v-if="
                  whitelabel === 'ICMEU' ||
                  whitelabel === 'ICMCapital' ||
                  whitelabel === 'ICMMENA' ||
                  whitelabel === 'GCCBrokers' ||
                  whitelabel === 'TradiNext'
                "
                class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
              >
                <dt class="text-sm my-auto leading-5 font-normal">
                  {{ $t("Nationality") }}
                </dt>
                <dd
                  v-if="showUpdateNationality"
                  class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="nationality"
                    rules="required"
                  >
                    <div class="rounded-md shadow-sm">
                      <v-select
                        v-model="updatedUser.nationality"
                        :clearable="false"
                        :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                        :options="countries"
                        :placeholder="
                          $t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          )
                        "
                        :reduce="(item) => item.iso_code"
                        class="style-chooser"
                        label="name"
                        @input="
                          (showUpdateNationalityPassportNumber = true),
                            updatedUser.nic_fields.length > 0
                              ? (updatedUser.nic_fields[0].value = '')
                              : null
                        "
                      ></v-select>
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-if="!showUpdateNationality"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <template>
                    {{ get_profile.nationality }}
                  </template>
                </dd>
                <dd
                  v-if="
                    (userHasPermission && whitelabel === 'GCCBrokers') ||
                    whitelabel === 'ICMEU' ||
                    whitelabel === 'TradiNext'
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateNationality"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateNationality = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateNationality"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateNationality = true"
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>

              <!-- National Passport Number -->

              <div
                v-if="
                  updatedUser.nationality !== null &&
                  (whitelabel === 'ICMCapital' ||
                    whitelabel === 'ICMEU' ||
                    whitelabel === 'ICMMENA' ||
                    whitelabel === 'GCCBrokers' ||
                    whitelabel === 'TradiNext')
                "
              >
                <div
                  v-for="(item, index) in get_profile_options.nic_fields"
                  :key="index"
                >
                  <!-- this will show  all inputs that are not in nic field array !nationality -->
                  <div v-if="item.hasOwnProperty('!nationality')">
                    <div
                      v-if="
                        !Object.values(item)[4].includes(
                          updatedUser.nationality
                        )
                      "
                    >
                      <!-- we are checking if updatedUser.nic_fields has data of nationality that was selected in input we want to edit
                      if it doesnt have, we insert its values in with nicCheck function -->
                      <div
                        v-if="
                          !updatedUser.nic_fields.find(
                            (obj) =>
                              obj.nationality === updatedUser.nationality &&
                              obj.priority === item.priority
                          )
                        "
                      >
                        {{
                          nicCheck(
                            item.priority,
                            updatedUser.nationality,
                            item.label
                          )
                        }}
                      </div>

                      <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm my-auto leading-5 font-normal">
                          {{ item.label }}
                          <!--                             
                          <br><br>
                          {{updatedUser.nic_fields}} <br> ******* ovo je updated user - nic fields
                          <br><br>
                          {{updatedUser.nic_fields.filter(obj => {
                            return obj.nationality === updatedUser.nationality && obj.priority === 1
                          })[0]}} 
                          <br><br>******* ovo je updated user - nic fields sa filterom -->
                        </dt>
                        <dd
                          v-if="showUpdateNationalityPassportNumber"
                          class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-1"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="national_passport_number"
                          >
                            <div class="rounded-md shadow-sm">
                              <input
                                id="national_passport_number_88"
                                v-model="
                                  updatedUser.nic_fields.filter((obj) => {
                                    return (
                                      obj.nationality ===
                                        updatedUser.nationality &&
                                      obj.priority === 1
                                    );
                                  })[0].value
                                "
                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              />
                            </div>
                            <span class="text-sm text-red-600 h-4">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </dd>
                        <dd
                          v-if="!showUpdateNationalityPassportNumber"
                          class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                        >
                          <template>
                            {{
                              updatedUser.nic_fields.filter((obj) => {
                                return (
                                  obj.nationality === updatedUser.nationality &&
                                  obj.priority === 1
                                );
                              })[0].value
                            }}
                          </template>
                        </dd>
                        <dd
                          v-if="
                            (userHasPermission &&
                              whitelabel === 'ICMCapital') ||
                            (userHasPermission &&
                              whitelabel === 'GCCBrokers') ||
                            (userHasPermission && whitelabel === 'TradiNext') ||
                            whitelabel === 'ICMEU'
                          "
                          class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                        >
                          <button
                            v-if="showUpdateNationalityPassportNumber"
                            class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                            type="reset"
                            @click="
                              (showUpdateNationalityPassportNumber = false),
                                cancel()
                            "
                          >
                            {{ $t("Cancel") }}
                          </button>
                          <button
                            v-if="showUpdateNationalityPassportNumber"
                            class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                            type="submit"
                          >
                            {{ $t("save-changes") }}
                          </button>
                          <div
                            v-else
                            class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                            @click="showUpdateNationalityPassportNumber = true"
                          >
                            {{ $t("update") }}
                          </div>
                        </dd>
                      </div>
                    </div>
                  </div>

                  <!-- this will show all inputs that are in nic_field with custom values -->
                  <div
                    v-else-if="
                      item.nationality.includes(updatedUser.nationality)
                    "
                  >
                    <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-sm my-auto leading-5 font-normal">
                        {{ item.label }}
                        <!-- {{updatedUser.nic_fields}} -->

                        <!-- we check if in updatedUser.nic_fields exist an object with nationality equal to selected nationality 
                        if there is none, we go to v-else and call a function that adds that objec in updatedUser.nic_field
                        so we can later send that with API -->
                        <div
                          v-if="
                            updatedUser.nic_fields.find(
                              (obj) =>
                                obj.nationality === updatedUser.nationality
                            )
                          "
                        >
                          <!-- sometimes nationality has 2 inputs with same nationality with different priority
                          so we check that in here and add that object if it is missing -->
                          <div
                            v-if="
                              !updatedUser.nic_fields.find(
                                (object) =>
                                  object.nationality ===
                                    updatedUser.nationality &&
                                  object.priority === item.priority
                              )
                            "
                          >
                            {{
                              nicCheck(
                                item.priority,
                                updatedUser.nationality,
                                item.label
                              )
                            }}
                          </div>
                        </div>
                        <div v-else>
                          {{
                            nicCheck(
                              item.priority,
                              item.nationality,
                              item.label
                            )
                          }}
                        </div>

                        <!-- {{updatedUser.nic_fields.filter(obj => {return obj.nationality.includes(updatedUser.nationality) && obj.priority === item.priority})[0] }}
                      <br>
                      {{updatedUser.nationality}} {{item.priority}}
                                  xxxxxxxxxxxxxxxxxxxxooooooooooooooo -->
                      </dt>
                      <dd
                        v-if="showUpdateNationalityPassportNumber"
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-1"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{ required: item.needed }"
                          name="national_passport_number"
                        >
                          <div class="rounded-md shadow-sm">
                            <input
                              id="national_passport_number_24"
                              v-model="
                                updatedUser.nic_fields.filter((obj) => {
                                  return (
                                    obj.nationality.includes(
                                      updatedUser.nationality
                                    ) && obj.priority === item.priority
                                  );
                                })[0].value
                              "
                              class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                          <span class="text-sm text-red-600 h-4">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </dd>
                      <dd
                        v-if="!showUpdateNationalityPassportNumber"
                        class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                      >
                        <template>
                          {{
                            updatedUser.nic_fields.filter((obj) => {
                              return (
                                obj.nationality.includes(
                                  updatedUser.nationality
                                ) && obj.priority === item.priority
                              );
                            })[0].value
                          }}
                        </template>
                      </dd>
                      <dd
                        v-if="
                          userHasPermission &&
                          (whitelabel === 'ICMCapital' ||
                            whitelabel === 'ICMEU' ||
                            whitelabel === 'GCCBrokers' ||
                            whitelabel === 'TradiNext')
                        "
                        class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                      >
                        <button
                          v-if="showUpdateNationalityPassportNumber"
                          class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                          type="reset"
                          @click="
                            (showUpdateNationalityPassportNumber = false),
                              cancel()
                          "
                        >
                          {{ $t("Cancel") }}
                        </button>
                        <button
                          v-if="showUpdateNationalityPassportNumber"
                          class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                          type="submit"
                        >
                          {{ $t("save-changes") }}
                        </button>
                        <div
                          v-else
                          class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                          @click="showUpdateNationalityPassportNumber = true"
                        >
                          {{ $t("update") }}
                        </div>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <!--  Date of birth-->
              <div
                v-if="get_profile.state !== 'Demo'"
                class="py-3 sm:grid sm:grid-cols-3 sm:gap-4"
              >
                <dt class="text-sm leading-5 font-normal my-auto">
                  {{ $t("date-of-birth") }}
                </dt>
                <dd
                  v-if="showUpdateDateOfBirth"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      minAgeRestriction: true,
                      maxAgeRestriction: true,
                      regex:
                        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d/,
                    }"
                    name="Invalid date format"
                  >
                    <div class="rounded-md shadow-sm">
                      <input
                        id="date_of_birth"
                        v-model="updatedUser.profile.date_of_birth"
                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        maxlength="10"
                        onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        size="10"
                      />
                    </div>
                    <span
                      v-if="errors[0] === 'Invalid date format'"
                      class="text-sm text-red-600 h-4"
                    >
                      {{ $t("please-enter-a-valid-date") }}</span
                    >
                    <span v-else class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  {{ get_profile.profile.date_of_birth | moment("DD/MM/YYYY") }}
                </dd>
                <dd
                  v-if="userHasPermission && whitelabel !== 'ICMMENA'"
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateDateOfBirth"
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="
                      (showUpdateDateOfBirth = false), cancel();
                      updatedUser.profile.date_of_birth =
                        get_profile.profile.date_of_birth;
                    "
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateDateOfBirth"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="
                      showUpdateDateOfBirth = true;
                      updatedUser.profile.date_of_birth = changeDateFormat(
                        get_profile.profile.date_of_birth
                      );
                    "
                  >
                    {{ $t("update") }}
                  </div>
                </dd>
              </div>
              <!--              Password-->
              <h3
                class="text-lg leading-6 font-normal text-gray-900 py-5 border-gray-200"
              >
                {{ $t("Password") }}
              </h3>
              <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm leading-5 font-normal my-auto">
                  <button
                    class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="showChangePasswordModal = true"
                  >
                    {{ $t("change-password-0b39c5aca15b84b1ad53a94d6b3feb78") }}
                  </button>
                </dt>
              </div>
              <div v-if="get_profile.state !== 'Demo'">
                <h3
                  v-if="
                    get_profile.address.addr_zip ||
                    get_profile.address.addr_line_2 ||
                    get_profile.address.addr_zip ||
                    (get_profile.address.addr_city &&
                      get_profile.address.addr_city !== '/') ||
                    (get_profile.address.addr_street &&
                      get_profile.address.addr_street !== '/')
                  "
                  class="text-lg leading-6 font-normal text-gray-900 py-5 border-b border-gray-200"
                >
                  {{ $t("Address") }}
                  <p
                    class="mt-1 pb-4 text-sm leading-5 font-light text-gray-500"
                  >
                    {{
                      $t("manage-how-information-is-displayed-on-your-account")
                    }}
                  </p>
                </h3>
                <div
                  v-if="whitelabel !== 'OneTradingMarkets'"
                  class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b"
                >
                  <dt class="text-sm leading-5 font-normal my-auto">
                    {{ $t("postcode") }}
                  </dt>
                  <dd
                    v-if="showUpdatePostcode"
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="postcode"
                      rules="required"
                    >
                      <div class="rounded-md shadow-sm">
                        <input
                          id="postcode"
                          v-model="updatedUser.address.addr_zip"
                          class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </dd>
                  <dd
                    v-else
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1 word-break"
                  >
                    {{ get_profile.address.addr_zip }}
                  </dd>
                  <dd
                    v-if="userHasPermission && whitelabel !== 'ICMMENA'"
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                  >
                    <button
                      v-if="showUpdatePostcode"
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="(showUpdatePostcode = false), cancel()"
                    >
                      {{ $t("Cancel") }}
                    </button>
                    <button
                      v-if="showUpdatePostcode"
                      class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="submit"
                    >
                      {{ $t("save-changes") }}
                    </button>
                    <div
                      v-else
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      @click="showUpdatePostcode = true"
                    >
                      {{ $t("Update") }}
                    </div>
                  </dd>
                </div>
                <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt class="text-sm leading-5 font-normal my-auto">
                    {{ $t("City") }}
                  </dt>
                  <dd
                    v-if="showUpdateCityOrTown"
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="city"
                      rules="required"
                    >
                      <div class="rounded-md shadow-sm">
                        <input
                          id="city"
                          v-model="updatedUser.address.addr_city"
                          class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </dd>
                  <dd
                    v-else
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1 word-break"
                  >
                    {{ get_profile.address.addr_city }}
                  </dd>
                  <dd
                    v-if="userHasPermission && whitelabel !== 'ICMMENA'"
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                  >
                    <button
                      v-if="showUpdateCityOrTown"
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="(showUpdateCityOrTown = false), cancel()"
                    >
                      {{ $t("Cancel") }}
                    </button>
                    <button
                      v-if="showUpdateCityOrTown"
                      class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="submit"
                    >
                      {{ $t("save-changes") }}
                    </button>
                    <div
                      v-else
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      @click="showUpdateCityOrTown = true"
                    >
                      {{ $t("Update") }}
                    </div>
                  </dd>
                </div>
                <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b">
                  <dt class="my-auto text-sm leading-5 font-normal my-auto">
                    {{ $t("address-line-1") }}
                  </dt>
                  <dd
                    v-if="showUpdateAddressLineI"
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ required: true, sixtyDigits: true }"
                      name="address_street"
                    >
                      <div class="rounded-md shadow-sm">
                        <input
                          id="street"
                          v-model="updatedUser.address.addr_street"
                          class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </dd>
                  <dd
                    v-else
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1 word-break"
                  >
                    {{ get_profile.address.addr_street }}
                  </dd>
                  <dd
                    v-if="userHasPermission && whitelabel !== 'ICMMENA'"
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                  >
                    <button
                      v-if="showUpdateAddressLineI"
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="(showUpdateAddressLineI = false), cancel()"
                    >
                      {{ $t("Cancel") }}
                    </button>
                    <button
                      v-if="showUpdateAddressLineI"
                      class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="submit"
                    >
                      {{ $t("save-changes") }}
                    </button>
                    <div
                      v-else
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      @click="showUpdateAddressLineI = true"
                    >
                      {{ $t("Update") }}
                    </div>
                  </dd>
                </div>
                <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm leading-5 font-normal my-auto">
                    {{ $t("address-line-2") }}
                  </dt>
                  <dd
                    v-if="showUpdateAddressLineII"
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="addr_line_2"
                      rules="required"
                    >
                      <div class="rounded-md shadow-sm">
                        <input
                          id="street2"
                          v-model="updatedUser.address.addr_line_2"
                          class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <span class="text-sm text-red-600 h-4">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </dd>
                  <dd
                    v-else
                    class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1 word-break"
                  >
                    {{ get_profile.address.addr_line_2 }}
                  </dd>
                  <dd
                    v-if="userHasPermission && whitelabel !== 'ICMMENA'"
                    class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                  >
                    <button
                      v-if="showUpdateAddressLineII"
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="(showUpdateAddressLineII = false), cancel()"
                    >
                      {{ $t("Cancel") }}
                    </button>
                    <button
                      v-if="showUpdateAddressLineII"
                      class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="submit"
                    >
                      {{ $t("save-changes") }}
                    </button>
                    <div
                      v-else
                      class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      @click="showUpdateAddressLineII = true"
                    >
                      {{ $t("Update") }}
                    </div>
                  </dd>
                </div>
                <h3
                  v-if="
                    whitelabel !== 'TD365' &&
                    whitelabel !== 'TDSouthAfrica' &&
                    whitelabel !== 'OrbitInvest'
                  "
                  class="text-lg leading-6 font-normal text-gray-900 py-5 border-b border-gray-200"
                >
                  {{ $t("Language") }}
                  <p
                    class="mt-1 pb-4 text-sm leading-5 font-light text-gray-500"
                  >
                    {{
                      $t("manage-how-information-is-displayed-on-your-account")
                    }}
                  </p>
                </h3>
              </div>
              <div
                v-if="
                  whitelabel !== 'TD365' &&
                  whitelabel !== 'TDSouthAfrica' &&
                  whitelabel !== 'OrbitInvest'
                "
                class="py-5 sm:grid sm:grid-cols-3 sm:gap-4"
              >
                <dt class="text-sm leading-5 font-normal my-auto">
                  {{ $t("Language") }}
                </dt>
                <dd
                  v-if="showUpdateLanguage"
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <div class="rounded-md shadow-sm">
                      <v-select
                        v-model="updatedUser.profile.language"
                        :clearable="false"
                        :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                        :options="languages"
                        :placeholder="
                          $t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          )
                        "
                        :reduce="(item) => item.value"
                        class="style-chooser"
                        label="display_name"
                      ></v-select>
                    </div>
                    <span class="text-sm text-red-600 h-4">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </dd>
                <dd
                  v-else
                  class="my-auto text-sm leading-5 font-light text-gray-900 sm:col-span-1"
                >
                  {{
                    languages.filter(
                      (item) => item.value === get_profile.profile.language
                    )[0].display_name
                  }}
                </dd>
                <dd
                  v-if="
                    !(
                      get_profile.state === 'Full' &&
                      (whitelabel === 'ICMCapital' ||
                        whitelabel === 'ICMMENA' ||
                        whitelabel === 'GCCBrokers' ||
                        whitelabel === 'TradiNext')
                    ) &&
                    whitelabel !== 'ICMMENA' &&
                    whitelabel !== 'VCGMarkets'
                  "
                  class="mt-1 text-sm leading-5 font-light text-gray-900 sm:mt-0 sm:col-span-1"
                >
                  <button
                    v-if="showUpdateLanguage"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="reset"
                    @click="(showUpdateLanguage = false), cancel()"
                  >
                    {{ $t("Cancel") }}
                  </button>
                  <button
                    v-if="showUpdateLanguage"
                    class="sm:float-right mr-2 float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="submit"
                  >
                    {{ $t("save-changes") }}
                  </button>
                  <div
                    v-else
                    class="sm:float-right float-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal my-auto rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    @click="showUpdateLanguage = true"
                  >
                    {{ $t("Update") }}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
          <change-password
            v-if="showChangePasswordModal"
            @close="close"
          ></change-password>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ChangePassword from "@/views/documents/profile-details/ChangePassword";
import Header from "@/components/Header";

export default {
  name: "PersonalInformation",
  components: {
    VuePhoneNumberInput,
    ChangePassword,
    Header,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      updatedUser: {},
      phone: "",
      showUpdateEmail: false,
      showUpdatePhone: false,
      showUpdateCompanyName: false,
      showUpdateFirstName: false,
      showUpdateLastName: false,
      showUpdateLanguage: false,
      showChangePasswordModal: false,
      showUpdateDateOfBirth: false,
      showUpdatePostcode: false,
      showUpdateAddressLineI: false,
      showUpdateAddressLineII: false,
      showUpdateCityOrTown: false,
      showUpdateNationality: false,
      showUpdateNationalityPassportNumber: false,
      nic_fields: [
        {
          priority: 1,
          value: "",
          label: "National Passport Number",
          nationality: "",
        },
      ],
      languages: [
        { value: "en", display_name: "English" },
        { value: "ar", display_name: "Arabic" },
        { value: "fa", display_name: "Persian" },
        { value: "zh-hans", display_name: "Chinese Simplified" },
        { value: "zh-hant", display_name: "Chinese Traditional" },
        { value: "nl", display_name: "Dutch" },
        { value: "ru", display_name: "Russian" },
        { value: "es", display_name: "Spanish" },
        { value: "th", display_name: "Thai" },
        { value: "id", display_name: "Indonesian" },
        { value: "ms", display_name: "Malay" },
        { value: "vi", display_name: "Vietnamese" },
        { value: "ko", display_name: "Korean" },
      ],
      languageDirection: "",
      phoneValid: false,
    };
  },
  computed: {
    ...mapGetters([
      "get_profile",
      "get_profile_options",
      "get_user_data",
      "get_uploaded_documents",
    ]),
    countries() {
      return this.$store.getters.get_countries;
    },
    userHasPermission() {
      let permission = false;
      if (this.get_profile.state === "Unsuitable Demo") {
        permission = true;
      }
      if (this.get_profile.state === "Pending") {
        permission = true;
      }
      if (
        (this.get_profile.state === "Pending" ||
          this.get_profile.state === "Review" ||
          this.get_profile.state === "Demo" ||
          this.get_profile.state === "KYC checks in progress") &&
        (this.whitelabel === "TDSouthAfrica" ||
          this.whitelabel === "TD365" ||
          this.whitelabel === "Blackstone" ||
          this.whitelabel === "OneTradingMarkets" ||
          this.whitelabel === "EagleGlobalMarkets" ||
          this.whitelabel === "TradeCoreUK" ||
          this.whitelabel === "ICMCapital" ||
          this.whitelabel === "ICMMENA" ||
          this.whitelabel === "GCCBrokers" ||
          this.whitelabel === "TradiNext" ||
          this.whitelabel === "ICMEU" ||
          this.whitelabel === "ICMAP" ||
          this.whitelabel === "ICMVC" ||
          this.whitelabel === "OrbitInvest")
      ) {
        permission = true;
      }
      if (this.get_profile.state === "Full") {
        permission = false;
      }
      return permission;
    },
    // on select forms, we need to set dir = 'rtl' instead of default dir='auto'
    // so non translated text would show the corect way on arab and persian pages
    checkLanguageDirection() {
      this.languageDirection = this.$store.getters.get_lang;
      if (this.lang === "ar" || this.lang === "fa") {
        return document.getElementById("body").dir;
      } else {
        return document.getElementById("body").dir;
      }
    },
  },
  mounted() {
    if (this.whitelabel === "ICMEU" || this.whitelabel === "ICMAP") {
      this.languages = [
        { value: "en", display_name: "English" },
        { value: "ar", display_name: "Arabic" },
        { value: "zh-hans", display_name: "Chinese Simplified" },
        { value: "zh-hant", display_name: "Chinese Traditional" },
        { value: "nl", display_name: "Dutch" },
        { value: "fa", display_name: "Persian" },
        { value: "ru", display_name: "Russian" },
        { value: "es", display_name: "Spanish" },
      ];
    } else if (
      this.whitelabel === "TD365" ||
      this.whitelabel === "TDSouthAfrica" ||
      this.whitelabel === "Blackstone" ||
      this.whitelabel === "EagleGlobalMarkets" ||
      this.whitelabel === "TradeCoreUK" ||
      this.whitelabel === "OrbitInvest"
    ) {
      this.languages = [{ value: "en", display_name: "English" }];
    } else if (this.whitelabel === "OneTradingMarkets") {
      this.languages = [
        { value: "en", display_name: "English" },
        { value: "ar", display_name: "Arabic" },
      ];
    } else if (this.whitelabel === "PolarisMarkets") {
      this.languages = [{ value: "en", display_name: "English" }];
    }
    this.$store.dispatch("profile").then(this.updateFormData);
    this.$store.dispatch("countries");
    this.$store.dispatch("profile_options");
  },
  methods: {
    // when user changes national passport number,
    // backend should receive label, nationality, priority, value
    // so those are the values that we write in updatedUser.nic_Fields
    nicCheck(priority, nationality, label) {
      var nic = {
        priority: priority,
        nationality: nationality,
        label: label,
      };
      this.updatedUser.nic_fields.push(nic);
    },
    // since beckend is sending date in different format
    // we need to change it to the format we use in front end
    changeDateFormat(date) {
      const moment = require("moment");
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    updateFormData() {
      this.updatedUser = this.get_profile;
    },
    save() {
      this.updatedUser.address.telephone = this.phone.formattedNumber;
      let data = {
        profile: this.updatedUser.profile,
        business_group: this.updatedUser.business_group,
        address: this.updatedUser.address,
        email: this.updatedUser.email,
        first_name: this.updatedUser.first_name,
        last_name: this.updatedUser.last_name,
      };
      if (
        this.whitelabel === "ICMEU" ||
        this.whitelabel === "ICMMENA" ||
        this.whitelabel === "GCCBrokers" ||
        this.whitelabel === "TradiNext"
      ) {
        data.nationality = this.updatedUser.nationality;
        data.nic_fields = [];

        data.nic_fields.push(
          this.updatedUser.nic_fields.filter((obj) => {
            return (
              obj.nationality.includes(this.updatedUser.nationality) &&
              obj.priority === 1 &&
              (obj.code === "undefined" || obj.code !== "CUST")
            );
          })[0]
        );

        // in case the nacional passport number has 2 inputs, we check if it exist and if it does, we push it in data.nic_fields
        // that we are going to send to backend
        var secondValue = this.updatedUser.nic_fields.filter((obj) => {
          return (
            obj.nationality.includes(this.updatedUser.nationality) &&
            obj.priority === 2 &&
            (obj.code === "undefined" || obj.code !== "CUST")
          );
        })[0];

        if (secondValue !== undefined) {
          data.nic_fields.push(
            this.updatedUser.nic_fields.filter((obj) => {
              return (
                obj.nationality.includes(this.updatedUser.nationality) &&
                obj.priority === 2 &&
                (obj.code === "undefined" || obj.code !== "CUST")
              );
            })[0]
          );
        }
      }

      // if(this.whitelabel === 'ICMCapital' && this.get_user_data.extra.client_type === 'company'){
      //   data.company_name = this.updatedUser.company_name;
      // }
      if (this.whitelabel === "OrbitInvest") {
        data.company_name = this.updatedUser.company_name;
      }

      this.$store
        .dispatch("update_user_data", data)
        .then(() => {
          this.showUpdateEmail = false;
          this.showUpdatePhone = false;
          // this.showUpdateCompanyName = false
          this.showUpdateFirstName = false;
          this.showUpdateLastName = false;
          this.showUpdateLanguage = false;
          this.showUpdateDateOfBirth = false;
          this.showUpdatePostcode = false;
          this.showUpdateAddressLineI = false;
          this.showUpdateAddressLineII = false;
          this.showUpdateCityOrTown = false;
          this.showUpdateNationality = false;
          this.showUpdateNationalityPassportNumber = false;
          if (this.whitelabel === "OrbitInvest") {
            this.showUpdateCompanyName = false;
          }
          this.$store.dispatch("profile").then(this.updateFormData);
          const t = this.$t.bind(this);
          this.$notify({
            group: "foo",
            text: `${t("your-personal-information-has-changed")}`,
          });
          this.$store.dispatch("user_data");
        })
        .catch((err) => {
          // error message is object > object > object > array
          // since we cannot target key value by name, we have to use object.keys[0] to get the first key/value
          // and we do that 2 times
          // but for nic_field error (ICMCapital) its one more layer of object before array
          // so we check if the first option is undefined, then we know its nic_field error so we target it by name
          // other error messages are for City, Address line 1, Address line 2 and Postcode
          let error = err.data[Object.keys(err.data)[0]];
          let errorMessage;
          if (typeof error[Object.keys(error)[0]] === "object") {
            if (error[Object.keys(error)[0]][0] === undefined) {
              if (
                err.data.nic_fields[0].non_field_errors[0] ===
                "Field length should be between 3 and 16"
              ) {
                errorMessage =
                  "National Passport Number should be between 3 and 16";
              }
            } else {
              errorMessage = error[Object.keys(error)[0]][0];
            }
          } else if (typeof error[Object.keys(error)[0]] === "string") {
            errorMessage = error[Object.keys(error)[0]];
          }

          // // ${Object.values(err.data)} bi radio posao ali mora ovako zbog nic field error poruke koja je drugacijeg formata
          // let errorMessage = err.data[Object.keys(err.data)[0]][0]
          // if(typeof  err.data[Object.keys(err.data)[0]][0] === 'object'){
          //   if(err.data.nic_fields[0].non_field_errors[0] === 'Field length should be between 3 and 16'){
          //     errorMessage = 'National Passport Number should be between 3 and 16'
          //   }
          // }

          this.$notify({
            group: "foo",
            text: `${errorMessage}`,
            type: "warn",
          });
        });
    },
    close() {
      this.showChangePasswordModal = false;
    },
    cancel() {
      // we need to reset phone value to match v-model updatedUser.address.telephone value from input
      // because the phone value gets saved when user clicks cancel
      this.phone = this.updatedUser.address.telephone;
      this.$store.dispatch("profile").then(this.updateFormData);
      this.showUpdateEmail = false;
      this.showUpdatePhone = false;
      // this.showUpdateCompanyName = false
      this.showUpdateFirstName = false;
      this.showUpdateLastName = false;
      this.showUpdateLanguage = false;
      this.showUpdateDateOfBirth = false;
      this.showUpdatePostcode = false;
      this.showUpdateAddressLineI = false;
      this.showUpdateAddressLineII = false;
      this.showUpdateCityOrTown = false;
      this.showUpdateNationality = false;
      this.showUpdateNationalityPassportNumber = false;
      if (this.whitelabel === "OrbitInvest") {
        this.showUpdateCompanyName = false;
      }
    },
    // "vue-phone-number-input" emits event that has a isValid value
    // if isValid is false, we call a validatePhone validations taht will return false
    // and a error message
    phoneValidateCheck(phone) {
      this.phoneValid = phone.isValid;
    },
  },
};
</script>
